<!--  -->
<template>
  <el-dialog
    width="31%"
    top="12vh"
    title="测试"
    :visible.sync="show"
    :close-on-click-modal="close"
    :close-on-press-escape="close"
    :show-close="close"
    :destroy-on-close="true"
    center>
    <div v-loading="loading" class="kaoshi-view">
      <div class="shiti">
        <div v-for="(item, index) in list" :key="index">
          <div class="msg">
            <h4>{{index+1}}、【{{item.tixing}}题】</h4>
            <div class="tishu">{{index+1}}/{{list.length}}</div>
          </div>
          <p>{{item.mingcheng}}</p>
          <div class="xuanze">
            <div v-for="(val, idx) in item.answer" :key="idx" @click="onChange(index, idx)">
              <div :class="{'xuanzhong': val.checked}">{{val.label}}</div>
              {{val.title}}
            </div>
          </div>
        </div>
      </div>
      <div class="btn">
        <el-button round type="success" class="self-button--success" size="medium" style="width: 160px" @click="handleSubmit">提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Api from '../../../api/gongxu'

export default {
  components: {},
  data() {
    return {
      show: false,
      loading: true,
      close: false,
      options: {},
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    init (options) {
      if (!this.show) {
        this.show = true
        this.options = options
        this.getTopic(options)
      }
    },
    getTopic (options) {
      Api.getTopic({
        vid: options.id,
        year: options.year,
      }).then(res => {
        this.loading = false
        if (res.data.code == 1) {
          this.list = [res.data.data]
          this.list.forEach(item => {
            if (item.tixing == '判断') {
              item.answer = [item.answer[0], item.answer[1]]
            }
            item.answer.forEach((val) => {
              val.label = val.title.split(":")[0]
              val.title = val.title.split(":")[1]
            })
          })
        } else {
          this.$message.error('题目信息获取失败！')
        }
      })
    },
    onChange (index, idx) {
      let info = this.list[index]
      info.answer[idx].checked = !info.answer[idx].checked
      if (info.tixing !== '多选') {
        for (let i in info.answer) {
          if (Number(i) != idx) {
            info.answer[i].checked = false
          }
        }
      }
      let true_answer = ''
      for (let i in info.answer) {
        if (info.answer[i].checked) {
          true_answer += info.answer[i].label
        }
      }
      info.sel_answer = true_answer
      if (true_answer === info.true_answer) {
        info.checked = true
      } else {
        info.checked = false
      }
      this.list[index] = info
    },
    handleSubmit () {
      let num = 0
      this.list.forEach(item => {
        if (item.checked) num++
      })
      if (num == this.list.length) {
        this.datiOver()
        this.show = false
        this.$emit('hideChange')
        this.$message.success('测试通过，继续学习')
      } else {
        this.$message.error('测试不通过，请重新选择')
      }
    },
    datiOver () {
      Api.datiOver({
        year: this.options.year,
        tanti_id: this.list[0].tanti_id,
        answer: this.list[0].sel_answer
      }).then(res => {
        if (res.data.code == 1) {
          console.log(res)
        } else {
          this.$message.error('测试失败，请重新答题')
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.kaoshi-view {
  width: 100%;
  height: 100%;
  .shiti {
    .msg {
      margin: 40px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4, div {
        color: @default;
      }
    }
    p {
      width: 90%;
      word-break: break-all;
      font-weight: 400;
      line-height: 25px;
    }
    .xuanze {
      > div {
        display: flex;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
        width: fit-content;
        div {
          border: 1px solid #d8d8d8;
          width: 25px;
          height: 25px;
          line-height: 25px;
          margin-right: 10px;
          text-align: center;
          border-radius: 50%;
          background-color: #fff;
        }
        .xuanzhong {
          border-color: @default;
          color: #fff;
          background-color: @default;
        }
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>