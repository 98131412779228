<!--  -->
<template>
  <div class='video-view'>
    <div class="nav">
      <div class="title">《{{info.keshi_name}}》</div>
      <!-- <div>
        <span>播放倍速：</span>
        <el-input value="1.0" readonly style="width: 200px"></el-input>
      </div> -->
    </div>
    <div class="myVideo">
      <video id="myVideo" autoplay controls="controls" controlslist="noplaybackrate nodownload noremoteplayback disablePictureInPicture" disablePictureInPicture v-if="info.keshi_url" oncontextmenu="return false;" ondragstart="return false;">
        <source :src="info.keshi_url" type="video/mp4" />
        <source src="movie.ogg" type="video/ogg" /> 对不起，您的浏览器暂不支持video视频标签!
      </video>
    </div>
    <div class="quit" @click="handleQuit">返回上一页面</div>

    <kao-shi ref="kaoshi" @hideChange="hideChange"></kao-shi>
  </div>
</template>

<script>
import KaoShi from './components/kaoshi/index.vue'
import Api from '../api/gongxu'

export default {
  components: {
    KaoShi
  },
  data() {
    return {
      info: {},
      currentTime: '',
      planTime: '',
      status: true,
      idx: 0
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getVideo()
    document.onkeydown = document.onkeyup = document.onkeypress = function(event) { 
      var e = event || window.event || arguments.callee.caller.arguments[0]; 
      if (e && e.keyCode == 123) { 
        e.returnValue = false; 
        return (false); 
      } 
    }
    document.oncontextmenu = new Function("return false;");
  },
  mounted() {
    
  },
  beforeDestroy () {
    document.onkeydown = document.onkeyup = document.onkeypress = () => {}
    document.oncontextmenu = () => {}
    if (this.info.finish == '1') {
      return
    }
    this.coursejilu(1)
    setTimeout(() => {
      window.οnbefοreunlοad = () => {}
    }, 500)
    clearInterval(this.planTime)
  },
  //方法集合
  methods: {
    getVideo () {
      Api.getVideo({
        vid: this.$route.query.id,
        year: this.$route.query.year,
      }).then(res => {
        // res.data.data.tanti_time = [res.data.data.study_time+10, ...res.data.data.tanti_time]
        if (res.data.code != 1) {
          this.$message.error('视频详情获取失败')
          return
        }
        if (res.data.data.state == 0) {
          this.$message.warning('不可同时播放多个视频!')
          this.$router.back()
          return;
        }
        this.info = res.data.data
        if (this.info.finish == '1') {
          return;
        }
        this.currentTime = res.data.data.study_time
        this.$nextTick(() => {
          setTimeout(() => {
            let video = document.getElementById("myVideo")
            video.currentTime = this.currentTime
            video.play()
            video.addEventListener("pause", () => {
              this.coursejilu()
            })
            window.addEventListener("beforeunload", (event) => {
							event.returnValue = '';
							this.coursejilu(1)
						}, false);
            if (this.info.tanti_time.indexOf(this.currentTime) != -1) {
              this.idx = this.info.tanti_time.indexOf(this.currentTime)
              video.pause()
              clearInterval(this.planTime)
              this.status = false
              this.$refs.kaoshi.init({
                id: this.$route.query.id,
                year: this.$route.query.year,
              })
              return;
            }
            this.playPlan()
            this.playOver()
            this.planTime = setInterval(() => {
              this.savePlayPlan()
            }, 60000)
          }, 200)
        })
      })
    },
    // 获取视频播放进度
    playPlan () {
      if (this.info.finish == '1') {
        return
      }
      var video = document.getElementById("myVideo");
      video.addEventListener("timeupdate", () => {
        if (!this.status) {
          video.pause()
          clearInterval(this.planTime)
          this.status = false
          this.$refs.kaoshi.init({
            id: this.$route.query.id,
            year: this.$route.query.year,
          })
        }
        let currentTime = Math.floor(video.currentTime);
        if (currentTime - this.currentTime > 5) {
          video.pause()
          video.currentTime = this.currentTime
        } else if (currentTime - this.currentTime == 1) {
          this.currentTime = currentTime
          if (this.info.tanti_time.indexOf(currentTime) != -1) {
            this.idx = this.info.tanti_time.indexOf(currentTime)
            video.pause()
            clearInterval(this.planTime)
            this.status = false
            this.$refs.kaoshi.init({
              id: this.$route.query.id,
              year: this.$route.query.year,
            })
          }
        }
      },false)
    },
    // 视频播放结束
    playOver () {
      if (this.info.finish == '1') {
        return
      }
      var video = document.getElementById("myVideo");
      video.addEventListener("ended", () => {
        let keshi_shichang = Number(this.info.keshi_shichang )
        if (keshi_shichang - this.currentTime > 5) {
          return;
        }
        let currentTime = video.currentTime
        this.currentTime = currentTime
        this.$confirm('本节课已学完,将跳转至课程列表选择其他课程', '温馨提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'info',
          center: true
        }).then(() => {
          Api.playOver({
            vid: this.$route.query.id,
            year: this.$route.query.year,
            study_time: currentTime
          }).then(res => {
            if (res.data.code == 1) {
              this.$confirm('请等待1个工作日，学习档案审核通过即可参加考试。', '温馨提示：恭喜您！所有课程已学完。', {
                confirmButtonText: '确定',
                showCancelButton: false,
                type: 'info',
                center: true
              }).then(() => {
                this.savePlayPlan()
                this.$router.back()
              }).catch(() => {})
            } else{
              this.savePlayPlan()
              this.$router.back()
            }
          })
        }).catch(()=>{})
      })
    },
    // 保存播放进度
    savePlayPlan () {
      Api.savePlayPlan({
        id: this.$route.query.id,
        year: this.$route.query.year,
        jilu_id: this.info.jilu_id,
        study_time: this.currentTime
      }).then(res => {
        if (res.data.state == 0) {
          this.$message.warning('不可同时播放多个视频!')
          this.$router.back()
          return;
        }
      })
    },
    // 保存播放进度
    coursejilu (exit) {
      Api.coursejilu({
        jilu_id: this.info.jilu_id,
        year: this.$route.query.year,
        currentTime: this.currentTime,
        exit: exit || ''
      })
    },
    hideChange () {
      this.status = true
      this.info.tanti_time.splice(this.idx, 1)
      this.$nextTick(() => {
        let video = document.getElementById("myVideo")
        video.play()
        this.playPlan()
        this.playOver()
        this.savePlayPlan()
        let planTime = setInterval(() => {
          this.savePlayPlan()
        }, 60000)
        this.planTime = planTime
      })
    },
    handleQuit () {
      this.$router.back()
    }
  },
}
</script>

<style lang='less' scoped>
.video-view {
  width: 70%;
  height: 70%;
  margin: 0 auto;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .title {
      span {
        color: @default;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .myVideo {
    margin-top: 10px;
    video {
      width: 100%;
      height: 100%;
    }
  }
  .quit {
    text-align: right;
    color: #409EFF;
    margin-top: 10px;
    font-size: 20px;
    padding-bottom: 5px;
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      width: 123px;
      height: 1px;
      background: #409EFF;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>